import React from "react";
import { Container, Paragraph } from "../components";
import styled from "styled-components";
import pattern from "../images/memphis-pattern.png";
import { size } from "../theme/theme";

const spacesFromParallax = -40;

const startingBackgroundPositionX = 110;
const startingBackgroundPositionY = 220;

const ParallaxContainer = styled.div`
  overflow: hidden;
  opacity: 1;
  line-height: 14vw;
  transition: all 0.4s ease;
  /* add margin  */
  margin-top: ${spacesFromParallax * -1 + 80}px;
  margin-bottom: ${spacesFromParallax}px;
`;

const PatternContainer = styled.div`
  padding: 90px 0;
  background-image: url(${pattern});
  transition: background-position 0.2s ease;
  background-position-x: ${startingBackgroundPositionX}%;
  background-position-y: ${startingBackgroundPositionY}%;
  background-attachment: fixed;

  @media ${size.xs} {
    padding: 130px 0;
  }
`;

const Space = styled.div`
  margin: 80px 0;
  @media ${size.xs} {
    margin: 120px 0;
  }
`;

const duringUniversity = ({ paragraph1, paragraph2 }) => {
  return (
    <>
      <ParallaxContainer>
        <PatternContainer>
          <Container>
            <Paragraph>{paragraph1}</Paragraph>
            <Space />
            <Paragraph>{paragraph2}</Paragraph>
          </Container>
        </PatternContainer>
      </ParallaxContainer>
    </>
  );
};

export default duringUniversity;
