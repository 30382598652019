import React, { useRef, useEffect } from "react";
import { Container, TitleAndAnnotation } from "../components";
import Image from "gatsby-image";
import styled from "styled-components";
import { size } from "../theme/theme";
import { gsap, ScrollTrigger } from "gsap/all";

const ImageContainer = styled.div`
  width: 100%;
  margin-left: 0;
  margin-top: 45px;
  margin-bottom: -180px;
  @media ${size.m} {
    margin-top: 0;

    max-width: 500px;
  }
`;

const BackendDev = ({ mainContent, image }) => {
  const imageRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top center",
        end: "bottom top",
        scrub: 3.5,
      },
    });
    tl.to(imageRef.current, {
      x: "20%",
      y: -20,
    });
  }, []);
  return (
    <div ref={containerRef}>
      <Container>
        <TitleAndAnnotation
          withLeftLine={mainContent.showLine}
          title={mainContent.title}
          annotation={mainContent.annotation}
          biggerAnnotation={mainContent.biggerAnnotation}
        />
        <ImageContainer ref={imageRef}>
          <Image fluid={image.asset.fluid} />
        </ImageContainer>
      </Container>
    </div>
  );
};

export default BackendDev;
