import React, { useRef, useEffect } from "react";
import { Container, Paragraph } from "../components";
import Image from "gatsby-image";
import styled, { css } from "styled-components";
import { size } from "../theme/theme";
import { gsap, ScrollTrigger } from "gsap/all";

const Space = styled.div`
  margin: 80px 0;
  @media ${size.m} {
    margin: 110px 0;
  }
`;

const GatsbyImage = styled(Image)`
  transition: transform 0s;
  width: 80%;
  top: 30px;
  right: -30px;
  opacity: 0.2;
  @media ${size.s} {
    width: 60%;
  }
  @media ${size.m} {
    top: 0;
    right: -180px;
    max-width: 500px;
  }
`;

const LetsBeHonest = ({ paraOne, paraTwo, image }) => {
  const gatsbyImage = useRef(null);
  const container = useRef(null);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: container.current,
        start: "top bottom",
        end: "bottom top",
        scrub: 1.5,
      },
    });
    tl.to(gatsbyImage.current, {
      x: -60,
      y: -250,
    });
  }, []);

  return (
    <div ref={container}>
      <Container>
        <Paragraph>{paraOne}</Paragraph>
        <Space />
        <div ref={gatsbyImage}>
          <GatsbyImage style={{ position: "absolute" }} fluid={image} />
        </div>
        <Paragraph>{paraTwo}</Paragraph>
      </Container>
    </div>
  );
};

export default LetsBeHonest;
