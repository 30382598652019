import React from "react";
import { Container, Paragraph } from "../components";
import Image from "gatsby-image";
import styled from "styled-components";
import { size } from "../theme/theme";

const FullScreenContainer = styled.div`
  position: relative;
  padding-bottom: 160px;
  @media ${size.m} {
    padding-bottom: 300px;
  }
  @media ${size.l} {
    padding-bottom: 390px;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  opacity: 0.8;
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
`;

const GradientOnImage = styled.div`
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.85) 38%,
    rgba(255, 255, 255, 0.4) 70%,
    rgba(255, 255, 255, 0.85) 93%,
    rgba(255, 255, 255, 1) 100%
  );
`;

const trustMeWebDev = ({ paragraph1, paragraph2, image }) => {
  return (
    <FullScreenContainer> 
      <Container>
        <Paragraph>{paragraph1}</Paragraph>
      </Container>
      <Container style={{ marginTop: "50px" }}>
        <Paragraph>{paragraph2}</Paragraph>
      </Container>
      <ImageContainer>
        <Image
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            bottom: 0,
          }}
          imgStyle={{
            backgroundPosition: "center bottom",
            objectPosition: "center bottom",
          }}
          fluid={image.asset.fluid}
        />
        <GradientOnImage />
      </ImageContainer>
    </FullScreenContainer>
  );
};

export default trustMeWebDev;
