import React from "react";
import { Container, TextReveal, ScrollMore, Headline } from "../components";
import { graphql, useStaticQuery } from "gatsby";
const hello = ({ elemLeft, elemRight, heroTitle }) => {
  return (
    <>
      <Container>
        <div>
          <Headline leftElem={elemLeft} rightElem={elemRight}></Headline>
        </div>
        <div>
          <h1>
            {heroTitle.map((elem) => (
              <TextReveal
                rotate={elem.doRotate}
                delay={elem.delay || undefined}
                key={elem._key}
              >
                {elem.text}
              </TextReveal>
            ))}
          </h1>
        </div>
        <div>
          <ScrollMore />
        </div>
      </Container>
    </>
  );
};

export default hello;
