import React, { useContext, useRef, useEffect, useCallback } from "react";
import { getChildImageSharpFromFilename } from "../utils";
import {
  Hello,
  LetsBeHonest,
  BackToUniversity,
  DuringUniversity,
  BackendDev,
  TrustMeWebDev,
  LivingAbroad,
} from "../sections";
import { myContext } from "../provider";
import styled, { css } from "styled-components";
import useOnScreen from "../hooks/useOnScreen";
import { size } from "../theme/theme";
import { Layout, SEO } from "../components";
import { graphql } from "gatsby";

const Section = styled.div`
  ${(props) => css`
    ${
      props.underHeader
        ? `
            height: calc(100vh - ${props.theme.spaces.headerHeight});
            margin: 10vh 0 -40vh 0;
            display: flex;
          `
        : css`
            margin: 140px 0 50px;
            @media ${size.m} {
              margin: 180px 0 80px;
            }
          `
    }
    ${
      props.snapScroll &&
      `
        scroll-snap-align: start;
      `
    }
    ${props.style}
  `}
`;

const IndexPage = ({ data }) => {
  const context = useContext(myContext);
  const sectionHello = useRef(null); // Before Computer science
  const sectionLetsBeHonest = useRef(null); // Before Computer science
  const sectionBackToUniversity = useRef(null); // Find the courage to reset
  const sectionDuringUniversity = useRef(null); // Find the courage to reset
  const sectionBackendDev = useRef(null); // Shift towards my passion
  const sectionTrustMeWebDev = useRef(null); // Shift towards my passion
  const sectionLivingAbroad = useRef(null); // Leap into the void

  const sectionHelloOnScreen = useOnScreen(sectionHello);
  const sectionBackToUniversityOnScreen = useOnScreen(sectionBackToUniversity);
  const sectionBackendDevOnScreen = useOnScreen(sectionBackendDev);
  const sectionLivingAbroadOnScreen = useOnScreen(sectionLivingAbroad);

  const setCurrentSectionKey = useCallback(() => {
    if (sectionHelloOnScreen) {
      context.set_currentSectionKey(1);
    } else if (sectionBackToUniversityOnScreen) {
      context.set_currentSectionKey(2);
    } else if (sectionBackendDevOnScreen) {
      context.set_currentSectionKey(3);
    } else if (sectionLivingAbroadOnScreen) {
      context.set_currentSectionKey(4);
    }
  });

  useEffect(() => {
    setCurrentSectionKey();
  }, [
    sectionHelloOnScreen,
    sectionBackToUniversityOnScreen,
    sectionBackendDevOnScreen,
    sectionLivingAbroadOnScreen,
    setCurrentSectionKey,
  ]);

  const onLoad = () => {
    const sections = [
      {
        key: 1,
        title: "Before computer science",
        ref: sectionHello,
      },
      {
        key: 2,
        title: "Find the courage to reset",
        ref: sectionBackToUniversity,
      },
      {
        key: 3,
        title: "Shift towards my passion",
        ref: sectionBackendDev,
      },
      {
        key: 4,
        title: "Leap into the void",
        ref: sectionLivingAbroad,
      },
    ];

    context.set_pageSections(sections);
  };

  useEffect(onLoad, []);

  return (
    <Layout>
      <SEO
        title="Thomas Morice: A passionate web developer"
        description="Hello, I'm Thomas, A passionate web developer originally from France and currently living in Sweden. Discover more about me and my passion"
      />
      <Section underHeader ref={sectionHello}>
        <Hello
          elemLeft={data.sanityHome.helloLeftText}
          elemRight={data.sanityHome.helloRightText}
          heroTitle={data.sanityHome.helloTitle}
        />
      </Section>
      <Section snapScroll ref={sectionLetsBeHonest}>
        <LetsBeHonest
          paraOne={data.sanityHome.honestParagraph1}
          paraTwo={data.sanityHome.honestParagraph2}
          image={data.sanityHome.honestImage.asset.fluid}
        />
      </Section>
      <Section snapScroll ref={sectionBackToUniversity}>
        <BackToUniversity
          mainContent={data.sanityHome.backToUniMainContent}
          imageWithText={data.sanityHome.backToUniImageWithText}
        />
      </Section>

      <Section ref={sectionDuringUniversity}>
        <DuringUniversity
          paragraph1={data.sanityHome.duringUniParagraph1}
          paragraph2={data.sanityHome.duringUniParagraph2}
        />
      </Section>

      <Section ref={sectionBackendDev}>
        <BackendDev
          mainContent={data.sanityHome.backendMainContent}
          image={data.sanityHome.backendSideImage}
        />
      </Section>
      <Section ref={sectionTrustMeWebDev}>
        <TrustMeWebDev
          paragraph1={data.sanityHome.webDevParagraph1}
          paragraph2={data.sanityHome.webDevParagraph2}
          image={data.sanityHome.webDevBackgroundImage}
        />
      </Section>
      <Section ref={sectionLivingAbroad}>
        <LivingAbroad
          title={data.sanityHome.livingAbroadTitle}
          paragraph1={data.sanityHome.livingAbroadParagraph1}
          paragraph2={data.sanityHome.livingAbroadParagraph2}
          image={data.sanityHome.livingAbroadImage}
        />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query homepageQuery {
    sanityHome {
      title
      helloLeftText
      helloRightText
      helloTitle {
        text
        doRotate
        delay
        _key
      }
      honestParagraph1
      honestParagraph2
      honestImage {
        asset {
          fluid(maxWidth: 600) {
            ...GatsbySanityImageFluid
          }
        }
      }
      backToUniMainContent {
        annotation
        title
        showLine
        biggerAnnotation
      }
      backToUniImageWithText {
        text
        image {
          asset {
            fluid(maxWidth: 420) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      duringUniParagraph1
      duringUniParagraph2
      backendMainContent {
        annotation
        title
        showLine
        biggerAnnotation
      }
      backendSideImage {
        asset {
          fluid(maxWidth: 500) {
            ...GatsbySanityImageFluid
          }
        }
      }
      webDevParagraph1
      webDevParagraph2
      webDevBackgroundImage {
        asset {
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      livingAbroadTitle
      livingAbroadParagraph1
      livingAbroadParagraph2
      livingAbroadImage {
        asset {
          fluid(maxWidth: 500) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;

export default IndexPage;
