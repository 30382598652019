import React, { useEffect } from "react";
import { Container, Paragraph, RotatingButton } from "../components";
import styled from "styled-components";
import TripSvg from "../images/trip.inline.svg";
import { size } from "../theme/theme";
import { navigate } from "gatsby";
import { gsap, ScrollTrigger, MotionPathPlugin } from "gsap/all";

const Title = styled.div`
  font: normal normal 700 28px/45px "Playfair Display", serif;
  letter-spacing: 0.05em;
  margin-bottom: 45px;
  @media ${size.xs} {
    font-size: 33px;
    line-height: 55px;
  }
  @media ${size.m} {
    max-width: 600px;
    font-size: 49px;
    line-height: 70px;
    margin-top: 50px;
  }
`;

const BackgroundImage = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  margin-left: 10px;
  margin-top: -50%;
  svg {
    width: 100%;
    height: auto;
    @media ${size.m} {
      width: 80%;
      margin-left: 15%;
    }
    @media ${size.l} {
      margin-top: 10%;
      width: 55%;
      margin-left: 20%;
    }
  }
`;

const LivingAbroad = ({ title, paragraph1, paragraph2 }) => {
  gsap.registerPlugin(MotionPathPlugin, ScrollTrigger);
  let tl;

  const timelineUpdate = () => {
    if (tl) {
      if (!document.querySelector("#road")) {
        // we need to clear the timeline if we change the page but the timeline is still active somehow
        tl.clear();
      }
    }
  };
  useEffect(() => {
    tl = gsap.timeline({
      scrollTrigger: {
        toggleActions: "play restart play reset",
        trigger: "#trip-svg",
        start: "top center",
        end: "bottom center",
      },
    });
    tl.eventCallback("onStart", timelineUpdate);
    tl.add("fly");
    tl.to(
      "#plane",
      {
        ease: "power1.inOut",
        duration: 12,
        motionPath: {
          path: "#road",
          autoRotate: true,
          align: "#road",
          alignOrigin: [0.5, 0.5],
        },
      },
      "fly"
    );
    tl.to(
      "#plane",
      {
        ease: "power3.inOut",
        duration: 5,
        delay: 1,
        repeat: 1,
        scale: 1.4,
        yoyo: true,
      },
      "fly"
    );
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      <Container>
        <Title>{title}</Title>
        <Paragraph>{paragraph1}</Paragraph>
        <div style={{ marginTop: "50px" }}>
          <Paragraph>{paragraph2}</Paragraph>
        </div>
      </Container>
      <BackgroundImage>
        <TripSvg id="trip-svg" />
      </BackgroundImage>
      <Container style={{ marginTop: "50px" }}>
        <RotatingButton
          style={{ maxWidth: "450px" }}
          frontText={"What happened in Sweden"}
          backText={"Check out my experiences"}
          onClick={() => navigate("/experiences/")}
        />
      </Container>
    </div>
  );
};

export default LivingAbroad;
